import _toConsumableArray from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import styles from './SliderCard.style.js';
import RichText from '../../atoms/RichText/index.js';
import Img from '../../atoms/Img/index.js';
import Anchor from '../../atoms/Anchor/index.js';
import useMediaQuery from '../../../hooks/useMediaQuery.js';
import Heading from '../../atoms/Heading/index.js';
import { MAX_BREAKPOINTS, BREAKPOINTS } from '../../../themes/breakpoints.js';
import Mailchimp from '../../atoms/Mailchimp/Mailchimp.js';

// to prevent hydration error
var Video = dynamic(function () {
  return import('../../atoms/Video/index.js');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('../../atoms/Video/index.js')];
    }
  }
});
function SliderCard(_ref) {
  var _heroData$textBlock, _heroData$customCodeC, _heroData$customCodeC2, _heroData$foregroundI;
  var className = _ref.className,
    heroData = _ref.heroData,
    isHero = _ref.isHero,
    mobileHeroVariation = _ref.mobileHeroVariation,
    options = _ref.options,
    blurredPageHeroBgImg = _ref.blurredPageHeroBgImg;
  var _ref2 = options || {},
    contrastType = _ref2.contrastType;
  var modifiers = {
    fontColor: heroData.fontColor === 'Light' ? 'nva-slidercard--light' : 'nva-slidercard--dark',
    mobileHeroVariation: mobileHeroVariation === 'Full' ? 'nva-slidercard--mobile-full' : 'nva-slidercard--mobile-split',
    variation: 'nva-slidercard--space-between',
    contrastType: 'nva-slidercard--no-contrast'
  };
  if (contrastType === 'Background') {
    modifiers.contrastType = 'nva-slidercard--contrast-bg';
  } else if (contrastType === 'Text Shadow') {
    modifiers.contrastType = 'nva-slidercard--contrast-text';
  }
  var alignment = 'nva-slidercard__content--left';
  var gridAlignment = 'nva-slidercard__content--grid-start';
  if (heroData.textLocation === 'Text Right') {
    alignment = 'nva-slidercard__content--right';
    gridAlignment = 'nva-slidercard__content--grid-end';
  } else if (heroData.textLocation === 'Center Bottom') {
    alignment = 'nva-slidercard__content--center';
    gridAlignment = 'nva-slidercard__content--grid-center';
  }
  var ctas = [heroData.cta].concat(_toConsumableArray(heroData.ctasCollection.items)).filter(Boolean);
  return __jsx("section", {
    className: cn('nva-slidercard', isHero && 'nva-slidercard--hero', modifiers.fontColor, modifiers.mobileHeroVariation, modifiers.variation, modifiers.contrastType, className)
  }, __jsx("div", {
    className: "nva-slidercard__wrapper"
  }, __jsx("div", {
    className: "nva-slidercard__bg-media-wrapper"
  }, heroData.video ? __jsx(SliderCard.BgVideo, {
    heroData: heroData,
    mobileHeroVariation: mobileHeroVariation,
    blurredPageHeroBgImg: blurredPageHeroBgImg
  }) : __jsx(SliderCard.BgImage, {
    heroData: heroData,
    mobileHeroVariation: mobileHeroVariation,
    blurredPageHeroBgImg: blurredPageHeroBgImg
  })), __jsx("div", {
    className: "l-grid nva-slidercard__content-grid"
  }, __jsx("div", {
    className: cn('nva-slidercard__content', alignment, gridAlignment)
  }, heroData.headline && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-slidercard__title"
  }, heroData.headline), heroData.subHeadline && __jsx("p", {
    className: "nva-slidercard__subtitle u-subtitle1"
  }, heroData.subHeadline), heroData.textBlock && __jsx(RichText, {
    className: "nva-slidercard__description",
    jsonContent: (_heroData$textBlock = heroData.textBlock) === null || _heroData$textBlock === void 0 ? void 0 : _heroData$textBlock.json
  }), ctas.length > 0 && __jsx(SliderCard.Ctas, {
    ctas: ctas
  }), ((_heroData$customCodeC = heroData.customCodeCollection) === null || _heroData$customCodeC === void 0 ? void 0 : _heroData$customCodeC.items.length) > 0 && __jsx("div", {
    className: "nva-slidercard__custom-wrapper"
  }, (_heroData$customCodeC2 = heroData.customCodeCollection) === null || _heroData$customCodeC2 === void 0 ? void 0 : _heroData$customCodeC2.items.map(function (item, index) {
    return __jsx(Mailchimp, {
      key: index,
      className: "nva-slidercard__custom",
      mailchimpdata: item
    });
  })), ((_heroData$foregroundI = heroData.foregroundImagesCollection) === null || _heroData$foregroundI === void 0 ? void 0 : _heroData$foregroundI.items.length) > 0 && __jsx(SliderCard.FgImages, {
    images: heroData.foregroundImagesCollection.items
  })))));
}
SliderCard.BgImage = function BgImage(_ref3) {
  var _heroData$video, _heroData$video$thumb, _heroData$video$thumb2, _heroData$video2, _heroData$video2$thum, _heroData$video2$thum2, _heroData$backgroundI, _heroData$backgroundI2, _heroData$backgroundI3, _heroData$backgroundI4, _heroData$backgroundI5, _heroData$backgroundI6, _heroData$backgroundI7, _heroData$backgroundI8, _heroData$video3, _heroData$video3$thum, _heroData$video3$thum2, _heroData$video4, _heroData$video4$thum, _heroData$video4$thum2, _heroData$backgroundI9, _heroData$backgroundI10, _heroData$backgroundI11, _heroData$backgroundI12, _heroData$video5, _heroData$video5$thum, _heroData$video5$thum2, _heroData$video6, _heroData$video6$thum, _heroData$video6$thum2, _heroData$backgroundI13, _heroData$backgroundI14, _heroData$backgroundI15, _heroData$backgroundI16;
  var heroData = _ref3.heroData,
    mobileHeroVariation = _ref3.mobileHeroVariation,
    isVideoBgImg = _ref3.isVideoBgImg,
    blurredPageHeroBgImg = _ref3.blurredPageHeroBgImg;
  var hasVideoImage = ((_heroData$video = heroData.video) === null || _heroData$video === void 0 ? void 0 : (_heroData$video$thumb = _heroData$video.thumbnail) === null || _heroData$video$thumb === void 0 ? void 0 : (_heroData$video$thumb2 = _heroData$video$thumb.image) === null || _heroData$video$thumb2 === void 0 ? void 0 : _heroData$video$thumb2.url) || ((_heroData$video2 = heroData.video) === null || _heroData$video2 === void 0 ? void 0 : (_heroData$video2$thum = _heroData$video2.thumbnail) === null || _heroData$video2$thum === void 0 ? void 0 : (_heroData$video2$thum2 = _heroData$video2$thum.mobileImage) === null || _heroData$video2$thum2 === void 0 ? void 0 : _heroData$video2$thum2.url);
  var imageSrc = ((_heroData$backgroundI = heroData.backgroundImage) === null || _heroData$backgroundI === void 0 ? void 0 : (_heroData$backgroundI2 = _heroData$backgroundI.image) === null || _heroData$backgroundI2 === void 0 ? void 0 : _heroData$backgroundI2.url) || ((_heroData$backgroundI3 = heroData.backgroundImage) === null || _heroData$backgroundI3 === void 0 ? void 0 : (_heroData$backgroundI4 = _heroData$backgroundI3.mobileImage) === null || _heroData$backgroundI4 === void 0 ? void 0 : _heroData$backgroundI4.url);
  var mobileSrc = ((_heroData$backgroundI5 = heroData.backgroundImage) === null || _heroData$backgroundI5 === void 0 ? void 0 : (_heroData$backgroundI6 = _heroData$backgroundI5.mobileImage) === null || _heroData$backgroundI6 === void 0 ? void 0 : _heroData$backgroundI6.url) || ((_heroData$backgroundI7 = heroData.backgroundImage) === null || _heroData$backgroundI7 === void 0 ? void 0 : (_heroData$backgroundI8 = _heroData$backgroundI7.image) === null || _heroData$backgroundI8 === void 0 ? void 0 : _heroData$backgroundI8.url);
  var videoImageSrc = ((_heroData$video3 = heroData.video) === null || _heroData$video3 === void 0 ? void 0 : (_heroData$video3$thum = _heroData$video3.thumbnail) === null || _heroData$video3$thum === void 0 ? void 0 : (_heroData$video3$thum2 = _heroData$video3$thum.image) === null || _heroData$video3$thum2 === void 0 ? void 0 : _heroData$video3$thum2.url) || ((_heroData$video4 = heroData.video) === null || _heroData$video4 === void 0 ? void 0 : (_heroData$video4$thum = _heroData$video4.thumbnail) === null || _heroData$video4$thum === void 0 ? void 0 : (_heroData$video4$thum2 = _heroData$video4$thum.mobileImage) === null || _heroData$video4$thum2 === void 0 ? void 0 : _heroData$video4$thum2.url) || ((_heroData$backgroundI9 = heroData.backgroundImage) === null || _heroData$backgroundI9 === void 0 ? void 0 : (_heroData$backgroundI10 = _heroData$backgroundI9.image) === null || _heroData$backgroundI10 === void 0 ? void 0 : _heroData$backgroundI10.url) || ((_heroData$backgroundI11 = heroData.backgroundImage) === null || _heroData$backgroundI11 === void 0 ? void 0 : (_heroData$backgroundI12 = _heroData$backgroundI11.mobileImage) === null || _heroData$backgroundI12 === void 0 ? void 0 : _heroData$backgroundI12.url);
  var videoMobileImageSrc = ((_heroData$video5 = heroData.video) === null || _heroData$video5 === void 0 ? void 0 : (_heroData$video5$thum = _heroData$video5.thumbnail) === null || _heroData$video5$thum === void 0 ? void 0 : (_heroData$video5$thum2 = _heroData$video5$thum.mobileImage) === null || _heroData$video5$thum2 === void 0 ? void 0 : _heroData$video5$thum2.url) || ((_heroData$video6 = heroData.video) === null || _heroData$video6 === void 0 ? void 0 : (_heroData$video6$thum = _heroData$video6.thumbnail) === null || _heroData$video6$thum === void 0 ? void 0 : (_heroData$video6$thum2 = _heroData$video6$thum.image) === null || _heroData$video6$thum2 === void 0 ? void 0 : _heroData$video6$thum2.url) || ((_heroData$backgroundI13 = heroData.backgroundImage) === null || _heroData$backgroundI13 === void 0 ? void 0 : (_heroData$backgroundI14 = _heroData$backgroundI13.mobileImage) === null || _heroData$backgroundI14 === void 0 ? void 0 : _heroData$backgroundI14.url) || ((_heroData$backgroundI15 = heroData.backgroundImage) === null || _heroData$backgroundI15 === void 0 ? void 0 : (_heroData$backgroundI16 = _heroData$backgroundI15.image) === null || _heroData$backgroundI16 === void 0 ? void 0 : _heroData$backgroundI16.url);

  // TODO: this is using old hero settings, optimize if possible
  // The order is important // TODO: should be able to organize differently
  var sourcesSettings = {
    '3xl': {
      '1x': {
        h: 567,
        w: 1600
      },
      '2x': {
        h: 1134,
        w: 3200
      }
    },
    xl: {
      '1x': {
        h: 567,
        w: 1485
      },
      '2x': {
        h: 1134,
        w: 2970
      }
    },
    lg: {
      '1x': {
        h: 567,
        w: 1195
      },
      '2x': {
        h: 1134,
        w: 2390
      }
    },
    md: {
      '1x': {
        h: 420,
        w: 800
      },
      '2x': {
        h: 840,
        w: 1600
      }
    },
    xs: {
      '1x': {
        h: 250,
        w: 414
      },
      '2x': {
        h: 500,
        w: 828
      }
    }
  };
  if (mobileHeroVariation === 'Full') {
    sourcesSettings.md = {
      '1x': {
        h: 468,
        w: 800
      },
      '2x': {
        h: 936,
        w: 1600
      }
    };
    sourcesSettings.xs = {
      '1x': {
        h: 468,
        w: 414
      },
      '2x': {
        h: 936,
        w: 828
      }
    };
  }
  return __jsx("div", {
    className: "nva-slidercard__bg-image-wrapper"
  }, __jsx(Img, {
    src: isVideoBgImg ? videoImageSrc : imageSrc,
    mobileSrc: isVideoBgImg ? videoMobileImageSrc : mobileSrc,
    sourcesSettings: hasVideoImage ? null : sourcesSettings,
    sizes: "(max-width: ".concat(MAX_BREAKPOINTS['3xl'], ") 100vw, (min-width: ").concat(BREAKPOINTS['3xl'], ") 1600px"),
    classes: {
      wrapper: 'nva-slidercard__bg-image-wrapper',
      picture: 'nva-slidercard__bg-picture',
      img: 'nva-slidercard__bg-image'
    },
    fill: true,
    priority: true,
    blurDataURL: blurredPageHeroBgImg
  }));
};
SliderCard.BgVideo = function BgVideo(_ref4) {
  var _heroData$video7;
  var heroData = _ref4.heroData,
    mobileHeroVariation = _ref4.mobileHeroVariation,
    blurredPageHeroBgImg = _ref4.blurredPageHeroBgImg;
  var isDesktop = useMediaQuery('lg');
  return __jsx("div", {
    className: "nva-slidercard__bg-video-wrapper"
  }, __jsx(SliderCard.BgImage, {
    heroData: heroData,
    isVideoBgImg: true,
    mobileHeroVariation: mobileHeroVariation,
    blurredPageHeroBgImg: blurredPageHeroBgImg
  }), __jsx(Video, {
    heroClassName: "nva-slidercard__video",
    url: heroData === null || heroData === void 0 ? void 0 : (_heroData$video7 = heroData.video) === null || _heroData$video7 === void 0 ? void 0 : _heroData$video7.url,
    optimize: true,
    autoplay: "1",
    controls: "0",
    loop: "1",
    fs: "0",
    mute: "1",
    isHero: true,
    isGreaterThanPhone: isDesktop,
    isLessThanDesktop: !isDesktop
  }));
};
SliderCard.Ctas = function Ctas(_ref5) {
  var ctas = _ref5.ctas;
  return __jsx("div", {
    className: "nva-slidercard__ctas-wrapper"
  }, ctas.map(function (cta, index) {
    return __jsx(Anchor, {
      key: index,
      className: "nva-slidercard__cta",
      title: cta.label,
      to: cta.url,
      hyperlinkType: cta.hyperlinkType,
      styleType: index > 0 ? 'secondary-anchor' : 'primary-anchor',
      type: cta.type,
      ctaBehavior: cta.behavior,
      "data-analytics-type": cta.anchorDataAnalyticsType,
      "data-analytics-value": cta.anchorDataAnalyticsValue,
      "data-analytics-variation": cta.anchorDataAnalyticsVariation,
      "aria-label": cta.ariaLabel
    }, cta.label);
  }));
};
SliderCard.FgImages = function FgImages(_ref6) {
  var images = _ref6.images;
  return __jsx("div", {
    className: "nva-slidercard__fg-image-wrapper"
  }, images.map(function (item, index) {
    return __jsx(React.Fragment, null, item.__typename === 'Mailchimp' && __jsx("div", {
      className: "nva-slidercard__custom",
      key: index,
      dangerouslySetInnerHTML: {
        __html: item.embedCode
      }
    }), item.__typename === 'Image' && __jsx(Img, {
      key: index,
      src: item.image.url,
      alt: item.image.title,
      classes: {
        wrapper: 'nva-slidercard__fg-image-wrapper',
        picture: 'nva-slidercard__fg-picture',
        img: 'nva-slidercard__fg-image'
      },
      height: item.image.height,
      width: item.image.width
    }));
  }));
};

// TODO: add prop types
// Slider.defaultProps = {
//
// };

// Slider.propTypes = {
//   className: PropTypes.string,
// };

export default styled(SliderCard).withConfig({
  componentId: "sc-1lqmbh9-0"
})(["", ";"], styles);